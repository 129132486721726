<template>
    <div>
        <a-table  size="small" bordered :scroll="{  y: 570 }" :columns="columns" :data-source="tableData" style="margin-top: 20px;" :loading="loading" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"   :pagination="pagination" >
            <span slot="action" slot-scope="text, record">
                <a-popconfirm
                        title="确定删除?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="confirmDelete('single', record)"
                        @cancel="cancelDelete"
                    >
                        <a>删除</a>
                    </a-popconfirm>
            </span>
        </a-table>
    </div>
</template>

<script>
import moment from 'moment'
import { queryComprehensiveDataApi, deleteComprehensiveDataApi } from '@/api/actual'
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 0
        },
        {
            title: '归集点',
            dataIndex: 'centerName',
            key: 'centerName',
            width: 0,
            ellipsis: true,
        },
        {
            title: '清洁屋',
            dataIndex: 'houseName',
            key: 'houseName',
            width: 0,
             ellipsis: true,
        },
        {
            title: '重量(kg)',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: '垃圾类别',
            dataIndex: 'typeName',
            key: 'typeName',
        },
        {
            title: '设备编号',
            dataIndex: 'deviceCode',
            key: 'deviceCode',
        },
        {
            title: '垃圾桶',
            dataIndex: 'trashCode',
            key: 'trashCode',
        },
        {
            title: '采集时间',
            dataIndex: 'collectTime',
            key: 'collectTime',
            width: 200,
            scopedSlots: { customRender: 'collectTime' },
        },
]
export default {
  data() {
    return {
        device: {},
        visible: false,
        rowSelection,
        selectedRowKeys: [],
        columns,
        loading: false,
        params: { },
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => {
            return '共' + total + '条'
            },
            pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
            onChange: (current, size) => this.changePage(current, size),
            onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
        },
        tableData: []
        }
    },
    filters: {
      formatDateTime(value) {
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    methods: {
      changePage(current, pageSize) {
        this.pagination.current = current
        this.pagination.pageSize = pageSize
        this.queryComprehensiveData(this.params.centerName)
      },
      cancelDelete() {
        this.selectedRowKeys = []
      },
      confirmDelete(type, data) {
          let ids = []
          if(type == 'multiple') {
              this.selectedRowKeys.forEach(item => {
                  ids.push(this.tableData[item].id)
              })
          } else {
              ids.push(data.id)
          }
          this.deleteByIds(ids)
      },
      deleteByIds(ids) {
        console.log('要删除的项' + ids)
        deleteComprehensiveDataApi(ids).then(res => {
            this.$message.success('删除成功')
            this.pagination.current = 1
            this.pagination.total = 0
            this.queryComprehensiveData(this.params.centerName)
            this.selectedRowKeys = []
        })
        .catch(() => {
            this.$message.error('删除失败')
        })
      },
       queryComprehensiveData(name) {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
            this.params.centerName = name
            queryComprehensiveDataApi({...this.params}).then(res => {
                this.loading = false
                this.tableData = res.result.content
                this.tableData.forEach(item => {
                    item.collectTime = moment(item.collectTime).format('YYYY-MM-DD HH:mm:ss')
                })
                this.pagination.current = res.result.pageNo + 1
                this.pagination.total = res.result.totalElements
                this.params.page = this.pagination.current
            })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
    }
}
</script>

<style lang="scss">
.device-detail-p{
    p{
        font-weight: bolder;
        font-size: 16px;
    }
}
</style>