<template>
       <a-modal
          :title="title"
          :visible="visible"
          @ok="handleOk"
          @cancel="handleCancel"
        >
            <a-form>
                <a-form-item label="订阅类型">
                    <a-select
                        placeholder="选择类型"
                        :value="selectedItems"
                        style="width: 100%"
                        @change="handleChange"
                    >
                        <a-select-option v-for="item in filteredOptions" :key="item.value" :value="item.value">
                        {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="小区编号">
                    <a-select
                        mode="multiple"
                        :value="finalCenterCodes"
                        style="width: 100%"
                        placeholder="选择"
                        @change="handleChangeCenterCodes"
                    >
                        <a-select-option v-for="i in centerCodes" :key="i.code" :value="i.code">
                        {{ i.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
</template>

<script>
import { addSubcribeApi } from '@/api/residential'
import _ from 'lodash'
const OPTIONS = [{'name': '园区', value: 'YUANQU'}, {'name': '昆山', value: 'KUNSHAN'}];
import { getToken } from '@/utils/auth'
export default {
    props: {
        title: {
            type: String,
            default: '三方订阅'
        },
    },
    data() {
        return {
            headers: {'accessToken': getToken()},
            visible: false,
            selectedItems: [],
            centerCodes: [],
            defalutCodes: [],
            finalCenterCodes: []
        }
    },
    computed: {
        filteredOptions() {
        return OPTIONS.filter(o => !this.selectedItems.includes(o));
        },
    },
    methods: {
        handleChange(selectedItems) {
            this.selectedItems = selectedItems;
            console.log(this.selectedItems)
        },
        handleChangeCenterCodes(selectedItems){
            this.finalCenterCodes = selectedItems
            console.log(this.finalCenterCodes)

        },
        handleOk() {
            this.visible = false
            console.log(this.finalCenterCodes, this.selectedItems)
            addSubcribeApi({type: this.selectedItems, centerCodes: this.finalCenterCodes}).then(res => {
                if(res.code == '0') {
                    this.$message.success('操作成功')
                    this.$emit('clearSelectedRowKeys')
                }
            })
            
        },
        handleCancel() {
            this.visible = false
        },
        showModal(codes) {
            this.visible = true
            this.defalutCodes = _.map(codes, 'code')
            this.centerCodes = codes
            this.finalCenterCodes = _.map(this.centerCodes, 'code')
        },
        hiddenModal() {
            this.visible = false
        },
    }
}
</script>