<template>
  <div class="factory-index">
    <a-form layout="inline" :labelCol="{span: 4}" :wrapperCol="{span: 20}">
      <a-row :gutter="20">
        <a-col :span="20">
          <a-form-item >
            <a-button type="primary" @click="onSearch">  查询 </a-button>
            <a-popconfirm
                title="确定删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="confirmDelete('multiple')"
                @cancel="cancelDelete"
            >
              <a-button type="danger" style="margin-left: 10px;" @click="deleteFactory">  批量删除 </a-button>
            </a-popconfirm>
          </a-form-item>

          <a-form-item>
              <a-date-picker show-time v-model="pushDate" />

              <a-popconfirm
                  title="发起一次数据推送?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="push"
                  @cancel="cancelDelete"
              >
                <a-button type="primary" style="margin-left: 10px;">  数据推送 </a-button>
              </a-popconfirm>

            <a-popconfirm
                title="根据时间推送数据信息?"
                ok-text="是"
                cancel-text="否"
                @confirm="confirmPushDate"
                @cancel="cancelDelete"
            >
              <a-button type="primary" style="margin-left: 10px;" >  时间数据推送 </a-button>
            </a-popconfirm>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-table  size="small" bordered  :columns="columns" :data-source="tableData" style="margin-top: 20px;" :loading="loading" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"   >
            <span slot="action" slot-scope="text, record">
                <a-popconfirm
                    title="确定删除?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirmDelete('single', record)"
                    @cancel="cancelDelete"
                >
                    <a>删除</a>
                </a-popconfirm>
            </span>
    </a-table>
  </div>

</template>

<script>
import addFactoryDrawer from '@/components/factoryMgt/component/addFactoryDrawer'
import moment from 'moment'
import {
  addLocalCenterApi,
  deleteLocalCenterApi,
  listLocalCenterApi,
  pushDateLocalCenterApi,
  pushLocalCenterApi
} from "@/api/localCenter";
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 0
  },
  {
    title: '小区名称',
    dataIndex: 'centerName',
    key: 'centerName',
    ellipsis: true,
    align: 'center'
  },
  {
    title: '小区编号',
    dataIndex: 'centerCode',
    key: 'centerCode',
    ellipsis: true,
    width: 0
  },
  {
    title: 'areaCode',
    dataIndex: 'areaCode',
    key: 'areaCode',
    ellipsis: true,
    align: 'center'
  },
  {
    title: 'cityCode',
    dataIndex: 'cityCode',
    key: 'cityCode',
    ellipsis: true,
    align: 'center'
  },
  {
    title: 'streetCode',
    dataIndex: 'streetCode',
    key: 'streetCode',
    ellipsis: true,
    align: 'center'
  },
  {
    title: 'subCenterCode',
    dataIndex: 'subCenterCode',
    key: 'subCenterCode',
    ellipsis: true,
    align: 'center'
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    width: 120,
    scopedSlots: { customRender: 'action' },
  }
]
export default {
  components: {addFactoryDrawer},
  data() {
    return {
      params: {},
      loading: false,
      rowSelection,
      selectedRowKeys: [],
      columns,
      tableData: [],
      pushDate: '',
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => {
          return '共' + total + '条'
        },
        pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
        onChange: (current, size) => this.changePage(current, size),
        onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
      },
    }
  },
  mounted() {
    this.queryFactoryList()
  },
  methods: {
    push() {
      pushLocalCenterApi().then(res => {
        if (res.code == '0') {
          this.$message.success('成功')
        }
      }).catch(() => {
        this.$message.error('失败')
      })
    },
    confirmPushDate() {
      console.log(this.pushDate)
      pushDateLocalCenterApi(this.pushDate.valueOf()).then(res => {
        if (res.code == '0') {
          this.$message.success('成功')
        }
      }).catch(() => {
        this.$message.error('失败')
      })
    },
    edit(record) {
      this.$refs.addFactoryDrawer.showDrawer(record, '编辑厂商')
    },
    cancelDelete() {
      this.selectedRowKeys = []
    },
    confirmDelete(type, data) {
      let ids = []
      if(type == 'multiple') {
        this.selectedRowKeys.forEach(item => {
          ids.push(this.tableData[item].centerCode)
        })
      } else {
        ids.push(data.centerCode)
      }
      this.deleteByIds(ids)
    },
    deleteByIds(ids) {
      console.log('要删除的项' + ids)
      deleteLocalCenterApi(ids).then(res => {
        this.$message.success('删除成功')
        this.pagination.current = 1
        this.pagination.total = 0
        this.queryFactoryList()
        this.selectedRowKeys = []
      })
          .catch(() => {
            this.$message.error('删除失败')
          })
    },
    queryFactoryList() {
      this.loading = true
      listLocalCenterApi({...this.params}).then(res => {
        this.tableData = res.result
        this.loading = false
      })
    },
    onSearch() {
      this.queryFactoryList()
    },
    addFactory() {
      this.$refs.addFactoryDrawer.showDrawer({}, '新增厂商')
    },
    deleteFactory() {},
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    changePage(current, pageSize) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.queryFactoryList()
    }
  }
}
</script>
<style lang="scss">
.factory-index{
  .ant-form-item{
    width: 100%;
  }
}
</style>
