<template>
    <div>
       <a-drawer
            title="小区详情"
            placement="right"
            :closable="false"
            :visible="visible"
            width="1000"
            @close="onClose"
        >
        <a-tabs  >
             <a-tab-pane :key="1" :tab="this.detailInfo.name">
                <div class="content-container" >
                    <a-row :gutter="20">
                        <a-col :span="12" v-for="(item, index) in detailItems"  :key="index" :style="{marginTop: (index > 1 ? '1%' : '')}">
                            <p style="font-weight: bolder;">{{item.label}}:</p>
                            <span v-if="!edit">
                                <!-- {{detailInfo[item.key]}} -->
                                <a-input :value="detailInfo[item.key]" disabled />
                                <a-button size="small" v-if="item.label == '详细地址'" @click="removeAddress">清除</a-button>
                                <a-button size="small" v-if="item.label == '详细地址'" type="primary" @click="showMap">选址</a-button>
                             </span>
                            <span v-else>
                                <a-input :value="detailInfo[item.key]" :disabled="item.disabled" style="width: 280px;" v-if="item.type == 'input'" @change="onChangeInput($event, item.key)"/>
                                <a-select v-if="item.subType == 'area'" style="width: 120px;" @focus="getFocus(item.subType)" @change="onChange($event, item.subType)">
                                    <a-select-option :value="item.regionCode" v-for="(item, index) in areaList" :key="index">
                                        {{item.regionName}}
                                    </a-select-option>
                                </a-select>
                                <a-select v-if="item.subType == 'street'" style="width: 120px;" @focus="getFocus(item.subType)" @change="onChange($event, item.subType)">
                                    <a-select-option :value="item.regionCode" v-for="(item, index) in streetList" :key="index">
                                        {{item.regionName}}
                                    </a-select-option>
                                </a-select>
                            </span>
                        </a-col>
                    </a-row>
                    <a-row :gutter="20" style="margin-top: 2%;">
                        <a-col :span="24">
                            <a-button type="primary" @click="toEdit" v-if="!edit">编辑</a-button>
                            <a-button type="primary" @click="save" v-if="edit">保存</a-button>
                            <a-button  @click="cancel" v-if="edit" style="margin-left: 10px;">取消</a-button>
                            <a-button  type="primary" @click="onClose" style="margin-left: 10px;">关闭</a-button>
                        </a-col>
                    </a-row>
                </div>
             </a-tab-pane>
              <a-tab-pane :key="2" tab="实时数据">
                <actual-data   ref="actualData"></actual-data>
              </a-tab-pane>
        </a-tabs>
        <select-map ref="map" @save="saveAddressChange"/>
        </a-drawer>
    </div>
</template>

<script>
import selectMap from './selectMap'
import actualData from './actualData'
import { updateResidentialCommunity } from '@/api/residential'
import Obj from '../dataStructure'
export default {
    components: { selectMap , actualData},
    data() {
    let self = this;
        return {
            visible: false,
            currentLocationInfo: {},
            map: null,
            markers: [],   
            markerOffset: [-130, -110],
            searchOption: {
                city: '全国',
                citylimit: false
            },
            zoom: 18,
            lng: 0,
            lat: 0,
            loaded: false,
            address: '',
            center: [0,0],
            //插件集合
            params: {
                address: {
                    address: '',
                    areaCode: '',
                    areaName: '',
                    cityCode: '',
                    cityName: '',
                    streetCode: '',
                    streetName: '',
                    communityCode: '',
                    communityName: '',
                    administrativeNumber: ''
                },
                coordinate: {
                    longitude: '',
                    latitude: ''
                },
                information: {
                    household: 0,
                    population: 0,
                    type: ''
                },
                meteringInfo: {
                    meteringStatus: '',
                    type: ''
                }
            },
            edit: false,
            detailItems: Obj.detailItems,
            detailInfo: {},
            statusList: [],
            device: [
                {
                    key: 1,
                    name: '归集点1'
                },
                {
                    key: 2,
                    name: '实时数据'
                }
            ],
            areaList: [],
            cityList: [],
            streetList: []
        }
    },
    methods: {
        showDrawer(record) {
            this.visible = true;
            let tmp = record
            this.params = {...tmp}
            this.address = tmp.address
            this.detailInfo = {...tmp.address, ...tmp.information, administrativeNumber: tmp.administrativeNumber, ...tmp.coordinate, id: tmp.id, ...tmp.meteringInfo, systemCode: tmp.systemCode}
            setTimeout(() => {
                this.$refs.actualData.queryComprehensiveData(this.detailInfo.name)
            }, 200)
            localStorage.setItem('fullPath', this.$route.fullPath)
        },
        onClose() {
            this.visible = false;
            this.$emit('query')
        },
        goBack() {
            this.$router.go(-1)
        },
        removeAddress() {
            this.address = ''
            this.params.coordinate.longitude = ''
            this.params.coordinate.latitude = ''
            this.save()
        },
        onChange(e, subType) {
            if(subType == 'area') {
                this.detailInfo.areaCode = e
                this.params.address.areaCode = e
                this.areaList.forEach(item => {
                    if(item.regionCode == e) {
                        this.params.address.areaName = item.regionName
                        this.detailInfo.areaName = item.regionName
                    }
                })
                this.detailInfo.streetName = ''
                this.detailInfo.streetCode = ''
                this.cityList = []
                // this.queryCity()
            }  else if(subType == 'street') {
                this.detailInfo.streetCode = e
                this.params.address.streetCode = e
                this.streetList.forEach(item => {
                    if(item.regionCode == e) {
                        this.params.address.streetName = item.regionName
                        this.detailInfo.streetName = item.regionName
                    }
                })
            }
        },
        getFocus(subType) {
            if(subType === 'area') {
                this.queryArea()
            } else if(subType == 'street') {
                this.queryStreet()
            }
        },
        queryArea() {
            this.$getRegionNodeChild().then(res => {
                this.areaList = res
            })
        },
        queryStreet() {
            this.$getRegionNodeChild(this.detailInfo.areaCode).then(res => {
                this.streetList = res
            })
        },
        onChangeInput(e, key) {
            this.detailInfo[key] = e.target.value
        },
        toEdit() {
            this.edit = true
        },
        save() {
            this.edit = false
            this.detailInfo.address = this.address.address
            this.params.address = {...this.address}
            this.params.address.areaCode = this.detailInfo.areaCode
            this.params.address.areaName = this.detailInfo.areaName
            this.params.address.streetCode = this.detailInfo.streetCode
            this.params.address.streetName = this.detailInfo.streetName
            this.params.information.name = this.detailInfo.name
            this.params.address.cityName = this.detailInfo.cityName
            this.params.information.household = this.detailInfo.household
            this.params.information.population = this.detailInfo.population
            updateResidentialCommunity({...this.params}).then(res => {
                if(res.code == '0'){
                    this.$message.success('修改成功')
                } else {
                    this.$message.error('修改失败')
                }
            }).catch(() => {

            })
        },
        cancel() {
            this.edit = false
        },
        showMap() {
            this.$refs.map.showMap(this.detailInfo)
        },
        saveAddressChange(data) {
            this.detailInfo= {...data}
            this.address.address = data.address
            this.params.coordinate.longitude = data.longitude
            this.params.coordinate.latitude = data.latitude
            this.save()
        }
    }
}
</script>
<style lang="scss">
.ant-input[disabled]{
    background: #fff;
    color: #000;
}
</style>