import service from './network';

export function addLocalCenterApi(data) {
    return service.request({
        url: '/management/localCenter/add',
        method: 'post',
        data,
    })
}

export function deleteLocalCenterApi(data) {
    return service.request({
        url: '/management/localCenter/delete',
        method: 'delete',
        data,
    })
}

export function pushLocalCenterApi(data) {
    return service.request({
        url: '/management/localCenter/push',
        method: 'put',
        data,
    })
}

export function pushDateLocalCenterApi(data) {
    return service.request({
        url: '/management/localCenter/push/date?date='+data,
        method: 'put',
    })
}

export function listLocalCenterApi(data) {
    return service.request({
        url: '/management/localCenter/list',
        method: 'get',
    })
}
