<template>
    <div class="index residential-index">
        <a-form :labelCol="{span: 4}" :wrapperCol="{span: 20}" layout="inline">
            <a-row :gutter="20">
                <a-col :span="8">
                    <a-form-item label="归集点名称">
                        <a-input v-model="params.name" allowClear placeholder="输入归集点名称" @change="onSearch"
                                 @pressEnter="onSearch"/>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="行政区划">
                        <a-select allowClear @change="handleChangeRegion">
                            <a-select-option v-for="(item, index) in regions" :key="index" :value="item.regionCode">
                                {{ item.regionName }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="行政街道">
                        <a-select allowClear @change="handleChangeStreet">
                            <a-select-option v-for="(item, index) in streets" :key="index" :value="item.regionCode">
                                {{ item.regionName }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="类别">
                        <a-select allowClear @change="handleChangeType">
                            <a-select-option v-for="(item, index) in residentialType" :key="index"
                                             :value="item.typeConstant">
                                {{ item.typeName }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="绑定状态">
                        <a-select allowClear style="width: 220px;" @change="handleChangeBindStatus">
                            <a-select-option :value="1">
                                已绑定
                            </a-select-option>
                            <a-select-option :value="2">
                                未绑定
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item>
                        <a-button type="primary" @click="onSearch"> 查询</a-button>
                        <a-button style="margin-left: 10px;" type="primary" @click="uploadResidential"> 批量导入
                        </a-button>
                        <a-button style="margin-left: 10px;" type="danger" @click="deleteResidentials"> 批量删除
                        </a-button>
                        <a-button style="margin-left: 10px;" type="primary" @click="showDocking"> 刷新对接状态
                        </a-button>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item>
                        <a-button style="margin-left: 10px;" type="primary" @click="ThirdPartySubscription"> 三方订阅
                        </a-button>
                        <a-button style="margin-left: 10px;" type="primary" @click="toResidentialUnDockReport">
                            未对接归集点报表
                        </a-button>
                      <a-button style="margin-left: 10px;" type="primary" @click="localCenterVisible=true">
                        延迟推送队列
                      </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
        <a-row justify="space-between" style="margin-top: 20px;" type="flex">
            <a-col :span="5"> 总共{{ this.pagination.total }}个归集点,每页{{ this.pagination.pageSize }}条</a-col>
        </a-row>
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :pagination="pagination"
                 :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                 :scroll="{ x: 1500, y: 620 }" bordered
                 size="small" style="margin-top: 20px;">
            <span slot="administrativeNumber" slot-scope="text, record" @click="copyQuick(record.administrativeNumber)">{{
                    record.administrativeNumber
                }}</span>
            <span slot="name" slot-scope="text, record" @click="copyQuick(record.name)">{{ record.name }}</span>
            <span slot="isBind" slot-scope="text, record">
              <a-tag v-if="record.isBind" color="blue">已绑定</a-tag>
              <a-tag v-if="!record.isBind" color="red">未绑定</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
                <a @click="viewDetail(record)">详情</a>
                <a-divider type="vertical"/>
                <a @click="dataPush(record)">数据推送</a>
                <a-divider type="vertical"/>
                <a @click="updateAdministrativeNumber(record)">修改市级编码</a>
                <a-divider type="vertical"/>
                <a @click="refresh(record)">刷新基础信息</a>
                <a-divider type="vertical"/>
                <a-popconfirm
                        cancel-text="取消"
                        ok-text="确定"
                        title="添加清洁屋?"
                        @confirm="addHouse(record)"
                >
                    <a>添加清洁屋</a>
                </a-popconfirm>
              <template  v-if="record.type !== 'village'">
                <a-divider type="vertical" />
              <a-popconfirm
                  cancel-text="取消"
                  ok-text="确定"
                  title="加入延迟推送队列?"
                  @confirm="addLocalCenter(record)"
              >
                    <a>延迟推送</a>
                </a-popconfirm>
              </template>

            </span>
        </a-table>
        <a-modal v-model="visible" cancelText="取消" okText="确定" title="修改市级编码" @ok="handleOk">
            <a-form>
                <a-form-item label="市级编码">
                    <a-input v-model="currentItem.administrativeNumber" placeholder="输入市级编码" size="large"/>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="pushVisible" cancelText="取消" okText="确定推送" title="推送数据" @ok="handleOkPush">
            <a-form>
                <a-form-item label="选择时间(不选则默认当天)">
                    <a-range-picker show-time @change="onChangePushTime">
                        <template slot="renderExtraFooter">
                            其他
                        </template>
                    </a-range-picker>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="dockingVisible" cancelText="取消" okText="确定刷新" title="刷新对接状态"
                 @ok="handleOkDocking">
            <a-form>
                <a-form-item label="Token">
                    <a-input v-model="Token" style="width: 350px;"/>
                </a-form-item>
            </a-form>
        </a-modal>
        <detail-drawer ref="detailDrawer" @query="queryResidentialCommunity"/>
        <upload-comm ref="uploadComm" action="https://www.zhongshihudong.com/garbage-collect-server/residential/upload"
                     title="上传归集点数据"
                     @querydata="queryResidentialCommunity"></upload-comm>
        <third-party-subscription ref="thirdPartySubscription" :centerCodes="centerCodes" title="三方订阅"
                                  @clearSelectedRowKeys="clearSelectedRowKeys"/>
        <a-modal v-model="residentialUnDockReportVisible" :width="1600" title="未对接归集点报表"
                 @ok="residentialUnDockReportVisible = false">
            <unDockReport/>
        </a-modal>

      <a-modal v-model="localCenterVisible" :width="1600" title="延迟推送队列"
               @ok="localCenterVisible = false">
        <local-center/>
      </a-modal>
    </div>
</template>

<script>
import ThirdPartySubscription from './component/thirdPatrySubscription.vue'
import detailDrawer from './component/detailDrawer'
import moment from 'moment'
import mixin from '@/utils/mixin';
import unDockReport from '@/components/residentialMgt/unDockReport.vue'
import uploadComm from '../common/uploadComm/index.vue'
import obj from './dataStructure'
import {exportCollectionApi} from '@/api/collection'
import {
    addHouseApi,
    deleteResidentialCommunity,
    exportResidentialCommunity,
    pushResidentialDataApi,
    queryResidentialCommunityList,
    queryResidentialTypeApi,
    refreshDockingApi,
    refreshResidentialDataApi,
    updateInfrastructureCodeApi,
    updateResidentialCodeApi
} from '@/api/residential'
import {addLocalCenterApi} from "@/api/localCenter";
import LocalCenter from "@/components/residentialMgt/component/localCenter.vue";

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
    },
};
const columns = obj.columns

const tableData = [];
export default {
    mixins: [mixin],
    components: {LocalCenter, uploadComm, detailDrawer, ThirdPartySubscription, unDockReport},
    data() {
        return {
            centerCodes: [],
            Token: '',
            dockingVisible: false,
            residentialType: [],
            jsonData: [],
            jsonFields: {},
            pushParams: {},
            pushVisible: false,
            currentItem: {},
            visible: false,
            tableDataBak: [],
            streets: [],
            regions: [],
            confirmLoading: false,
            showModal: false,
            params: {},
            loading: false,
            selectedRowKeys: [],
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                    return '共' + total + '个归集点信息'
                },
                pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange: (current, pageSize) => this.changePage(current, pageSize),
            },
            rowSelection,
            value: [],
            tableData,
            columns,
            residentialUnDockReportVisible: false,
          localCenterVisible:false,
        };
    },
    mounted() {
        this.$getRegionNodeChild('').then(res => {
            this.regions = res
        })
        this.queryResidentialCommunity()
        this.queryAllResidentialData()
        this.queryResidentialType()
    },
    methods: {
      addLocalCenter(record) {
        addLocalCenterApi([record.systemCode]).then(res => {
          if (res.code == '0') {
            this.$message.success('添加成功')
          }
        }).catch(() => {
          this.$message.error('添加失败')
        })
      },
        toResidentialUnDockReport() {
            this.residentialUnDockReportVisible = true
        },
        clearSelectedRowKeys() {
            this.selectedRowKeys = []
        },
        ThirdPartySubscription() {
            this.centerCodes = []
            this.selectedRowKeys.forEach(item => {
                this.centerCodes.push({code: this.tableData[item].systemCode, name: this.tableData[item].name})
            })
            this.$refs.thirdPartySubscription.showModal(this.centerCodes)
        },
        handleChangeBindStatus(e) {
            this.params.isBind = (e == 1 ? true : (e == 2 ? false : ''))
            this.onSearch()
        },
        handleOkDocking() {
            refreshDockingApi(this.Token).then(res => {
                if (res.code == '0') {
                    this.$message.success('刷新成功')
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        showDocking() {
            this.dockingVisible = true
            this.Token = ''
        },
        queryResidentialType() {
            queryResidentialTypeApi().then(res => {
                this.residentialType = res.result
            })
        },
        addHouse(record) {
            addHouseApi(record.systemCode).then(res => {
                if (res.code == '0') {
                    this.$message.success('添加成功')
                    this.queryResidentialCommunity()
                }
            }).catch(() => {
                this.$message.error('添加失败')
            })
        },
        updateCode(record) {
            this.$message.loading('程序执行中', 2.5)
            updateInfrastructureCodeApi({id: record.id}).then(res => {
                this.$message.success('修改成功')
                this.queryResidentialCommunity()
            })
        },
        refresh(record) {
            refreshResidentialDataApi(record.systemCode).then(res => {
                this.$message.success('刷新成功')
            }).catch(() => {
                this.$message.error('刷新失败')
            })
        },
        queryAllResidentialData() {
            let param = {
                page: 1,
                limit: 1900
            }
            queryResidentialCommunityList({...param}).then(res => {
                this.filterTableData(res.result.content, 'jsonData')
            })
            this.jsonFields = {}
            this.columns.forEach((item, index) => {
                if (index < this.columns.length - 1) {
                    this.jsonFields[item.title] = item.dataIndex
                }
            })
        },
        onChangePushTime(e) {
            this.pushParams.startTime = e[0]
            this.pushParams.endTime = e[1]
        },
        dataPush(record) {
            this.pushVisible = true
            this.pushParams.systemCode = record.systemCode
        },
        handleOkPush() {
            pushResidentialDataApi({...this.pushParams}).then(res => {
                this.$message.success('操作成功，数据推送已开始')
                this.pushVisible = false
            }).catch(() => {
                this.$message.error('操作失败')
            })
        },
        updateAdministrativeNumber(record) {
            this.visible = true
            this.currentItem.administrativeNumber = record.administrativeNumber
            this.currentItem.id = record.id
        },
        handleOk() {
            updateResidentialCodeApi({...this.currentItem}).then(res => {
                this.$message.success('修改成功')
                this.visible = false;
                this.queryResidentialCommunity()
            }).catch(() => {
                this.$message.error('修改失败')
            })
        },
        viewDetail(record) {
            this.tableDataBak.forEach(item => {
                if (item.id === record.id)
                    this.$refs.detailDrawer.showDrawer(item)
            })
        },
        handleChangeRegion(e) {
            this.params.areaCode = e
            this.$getRegionNodeChild(e).then(res => {
                this.streets = res
            })
        },
        handleChangeStreet(e) {
            this.params.streetCode = e
        },
        handleChangeType(e) {
            this.params.typeConstant = e
            this.onSearch()
        },
        exportCollection(record) {
            exportCollectionApi({residentialCode: record.systemCode}).then(res => {
                var blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                var downloadElement = document.createElement('a')
                var href = window.URL.createObjectURL(blob) // 创建下载的链接
                downloadElement.href = href
                downloadElement.download = record.name + '采集点数据.xlsx'
                document.body.appendChild(downloadElement)
                downloadElement.click() // 点击下载
                document.body.removeChild(downloadElement) // 下载完成移除元素
                window.URL.revokeObjectURL(href)
            })
        },
        onSearch() {
            this.pagination.current = 1
            this.pagination.total = 0
            this.queryResidentialCommunity()
        },
        uploadResidential() {
            this.$refs.uploadComm.showModal()
        },
        exportResidential() {
            exportResidentialCommunity({areaCode: '', streetCode: ''}).then(res => {
                var blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                var downloadElement = document.createElement('a')
                var href = window.URL.createObjectURL(blob) // 创建下载的链接
                downloadElement.href = href
                downloadElement.download = '归集点数据.xls'
                document.body.appendChild(downloadElement)
                downloadElement.click() // 点击下载
                document.body.removeChild(downloadElement) // 下载完成移除元素
                window.URL.revokeObjectURL(href)
            })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        deleteResidentials() {
            let ids = []
            this.selectedRowKeys.forEach(item => {
                ids.push(this.tableData[item].id)
            })
            if (ids.length > 0) {
                deleteResidentialCommunity(ids).then(res => {
                    this.$message.success('删除成功')
                    this.pagination.current = 1
                    this.pagination.total = 0
                    this.queryResidentialCommunity()
                    this.selectedRowKeys = []

                })
                                               .catch(() => {
                                                   this.$message.error('删除失败')
                                               })
            } else {
                this.$message.warning('请先选择要删除的项')
            }

        },
        changePage(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.queryResidentialCommunity()
        },
        queryResidentialCommunity() {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
            queryResidentialCommunityList({...this.params}).then(res => {
                this.loading = false
                this.tableDataBak = res.result.content
                this.filterTableData(res.result.content, 'tableData')
                this.pagination.current = res.result.pageNo + 1
                this.pagination.total = res.result.totalElements
                this.params.page = this.pagination.current
            })
        },
        filterTableData(data, name) {
            this[name] = []
            let array1 = []
            let obj = {}
            data.forEach((item, index) => {
                obj.id = item.id
                obj.isBind = item.isBind
                obj.administrativeNumber = item.administrativeNumber
                obj.systemCode = item.systemCode
                obj.name = item.information.name
                obj.household = item.information.household
                obj.population = item.information.population
                obj.collectionPoint = item.information.collectionPoint
                obj.type = item.information.type
                obj.typeName = item.information.typeName
                obj.address = item.address.address
                obj.areaName = item.address.areaName
                obj.cityName = item.address.cityName
                obj.streetName = item.address.streetName
                obj.longitude = item.coordinate.type
                obj.latitude = item.coordinate.latitude
                obj.isFresh = item.isFresh
                obj.areaCode = item.address.areaCode
                obj.streetCode = item.address.streetCode
                obj.uploadDate = moment(item.information.uploadDate).format('YYYY-MM-DD') == 'Invalid date' ? '' : moment(item.information.uploadDate).format('YYYY-MM-DD')
                array1.push(obj)
                obj = {}
            })
            this[name] = array1
        }
    }
};
</script>

<style lang="scss">
.index {
  /* background: #F5F7F9; */
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}

.residential-index {
  .ant-form-item {
    width: 100%;
  }
}

.ant-form {
  text-align: initial;
}

</style>
