<template>
  <div class="content">
    <span>是否过滤：</span>
    <a-select  @change="handleChange"   style="width: 200px;margin-bottom: 10px;margin-left:20px;" :defaultValue="defaultValue">
          <a-select-option :value="true">
              过滤
          </a-select-option>
          <a-select-option :value="false">
              不过滤
          </a-select-option>
    </a-select>
    <iframe :src="src" style="width: 100%; height: 83vh; display: block; border: 0px;"></iframe>
  </div>

</template>

<script>
import {getToken} from "@/utils/auth";
import {baseURL} from '@/api/network'

export default {
  name: "unDockReport",
  data() {
    return {
      raw: baseURL+'/jmreport/view/575558814685171712',
      // raw: 'http://192.168.0.167:8081/jmreport/view/575558814685171712',
      src: '',
      defaultValue: true
    }
  },
  created() {
    this.generateSrc()
  },
  methods: {
    handleChange(e) {
      const token = getToken()
      this.raw = `${baseURL}/jmreport/view/575558814685171712?token=${token}&isFilter=${e}`
      this.customQuery()
    },
    customQuery() {
        this.src = `${this.raw}`
    },
    generateSrc() {
      const token = getToken()
      this.raw = `${baseURL}/jmreport/view/575558814685171712?token=${token}&isFilter=${true}`
      this.customQuery()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>

