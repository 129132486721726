export default {
    columns: [
        {
            title: '系统编号',
            dataIndex: 'systemCode',
            key: 'systemCode',
            width: 150
        },
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 0,
            scopedSlots: { customRender: 'id' },
        },
        {
            title: '市级编码',
            dataIndex: 'administrativeNumber',
            key: 'administrativeNumber',
            width: 150,
            scopedSlots: { customRender: 'administrativeNumber' },
        },
        {
            title: '归集点名称',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            scopedSlots: { customRender: 'name' },
        },
        {
            title: '采集点数量(个)',
            dataIndex: 'collectionPoint',
            key: 'collectionPoint',
            width: 100,
            align: 'center',
        },
        {
            title: '小区类别',
            dataIndex: 'typeName',
            key: 'typeName',
            ellipsis: true,
            width: 100,
            align: 'center',
        },
        {
            title: '所属区域',
            dataIndex: 'areaName',
            key: 'areaName',
            width: 200,
            align: 'center',

        },
        {
            title: '绑定状态',
            dataIndex: 'isBind',
            key: 'isBind',
            width: 200,
            align: 'center',
            scopedSlots: { customRender: 'isBind' },
        },
        {
            title: '上传时间',
            dataIndex: 'uploadDate',
            key: 'uploadDate',
            ellipsis: true,
            align: 'center',
            width: 200
        },
        {
            title: '经纬度',
            dataIndex: 'longitude',
            key: 'longitude',
            ellipsis: true,
            width: 200
        },
        // {
        //     title: '是否是最新编码',
        //     dataIndex: 'isFresh',
        //     key: 'isFresh',
        //     scopedSlots: { customRender: 'isFresh' },
        // },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 300,
            scopedSlots: { customRender: 'action' },
        }
    ],
    detailItems: [
        {
            label: '归集点编号',
            key: 'id',
            value: 1,
            type: 'input',
            disabled: true
        },
        {
            label: '归集点名称',
            key: 'name',
            value: 1,
            type: 'input',
            disabled: false

        },
        {
            label: '归集点住户数',
            key: 'household',
            value: 201,
            type: 'input',
            disabled: false

        },
        {
            label: '人口数量',
            key: 'population',
            value: 1,
            type: 'input',
            disabled: false

        },
        {
            label: '小区类别',
            key: 'type',
            value: 1,
            type: 'input',
            disabled: true

        },
        {
            label: '详细地址',
            key: 'address',
            value: '江苏省苏州市xxxxx',
            type: 'input',
            disabled: false
        },
        {
            label: '所属区域',
            key: 'areaName',
            value: '苏州市',
            type: 'select',
            subType: 'area',
            disabled: false
        },
        {
            label: '所属城市',
            key: 'cityName',
            value: '高新区',
            type: 'input',
            disabled: false
        },
        {
            label: '所属街道',
            key: 'streetName',
            value: '狮山街道',
            type: 'select',
            subType: 'street',
            disabled: false


        },
        {
            label: '经纬度',
            key: 'longitude',
            value: '120.5555',
            type: 'input',
            disabled: true


        },
        {
            label: '纬度',
            key: 'latitude',
            value: '67.000',
            type: 'input',
            disabled: true

        },
        {
            label: '采集点',
            key: 'collectionPoint',
            value: '御景花园',
            type: 'input',
            disabled: true

        }
    ]
}