<template>
    <a-drawer
      :title="type"
      width="520"
      :closable="false"
      :visible="visible"
      @close="onClose"
    >
    <a-row>
        <a-col :span="24" v-for="(item, index) in formItems" :key="index" style="margin-bottom: 20px;">
            <a-row :gutter="20">
                <a-col :span="5">
                    <h3 style="line-height: 45px;font-weight: bolder;">{{item.chname}}:</h3>
                </a-col>
                <a-col :span="19">
                    <a-input :disabled="item.disable" style="width: 100%;height: 45px;color: #000; font-size: 20px;" v-model="params[item.name]"></a-input>
                </a-col>
            </a-row>
        </a-col>
    </a-row>
        <a-row> <a-col :span="24"> <p style="color: red;">{{errorMsg}}</p></a-col></a-row>
        <div
            :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
            }"
        >
            <a-button style="marginRight: 8px" @click="onClose">
            取消
            </a-button>
            <a-button type="primary" @click="onConfirm">
            确认
            </a-button>
        </div>
    </a-drawer>
</template>
<script>
import { updateFactoryApi } from '@/api/factory'
export default {
    data() {
        return {
            type: '',
            params: {},
            errorMsg: '',
            visible: false,
            formItems: [
                {
                    name: 'factoryCode',
                    chname: '厂家编号',
                    type: 'input',
                    disable: true,
                    blankError: '厂家编号不能为空',
                    tips: '系统自动生成'
                },
                {
                    name: 'factoryName',
                    chname: '厂家名称',
                    type: 'input',
                    disable: false,
                    blankError: '厂家名称不能为空',
                    tips: '系统自动生成'
                },
                {
                    name: 'plate',
                    type: 'input',
                    chname: '板块',
                    disable: false,
                    blankError: '板块不能为空',
                    tips: '系统自动生成'
                },
                {
                    name: 'devicePrefix',
                    type: 'input',
                    chname: '设备前缀',
                    disable: false,
                    blankError: '设备前缀不能为空',
                    tips: '系统自动生成'
                },
                {
                    name: 'chipPrefix',
                    type: 'input',
                    chname: '芯片前缀',
                    disable: false,
                    blankError: '芯片前缀不能为空',
                    tips: '系统自动生成'
                }
            ]

        }
    },
    methods: {
        onConfirm(){
            this.errorMsg = ''
            this.formItems.forEach(item => {
                if(!this.params[item.name] && item.name !== 'factoryCode') {
                    this.errorMsg += item.blankError + '|'
                }
            })
            if(this.errorMsg == '') {
                this.updateFactory()
            }
        },
        updateFactory() {
            updateFactoryApi({...this.params}).then(res => {
                if(res.code == '0') {
                    this.visible = false
                    this.$message.success(this.type + '成功')
                    this.$emit('query')
                } else {
                    this.errorMsg = res.message
                }
            })
        },
        onClose() {
            this.visible = false
        },
        showDrawer(record, type) {
            this.type = type
            this.params = {...record}
            this.visible = true
        },
    }
}
</script>