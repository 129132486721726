<template>
  <a-modal title="选址" v-model="visible" width="1000px" :footer="null" @cancel="handleCancel">
    <a-input id="tipinput" v-model="name"/>当前选中：{{address}}{{longitude}},{{latitude}}
    <a-button style="margin-left: 10px" type="primary" @click="save">保存</a-button>
    <div
      :id="mapId"
      style="width:100%;height:80vh"
      class="m-map"/>
  </a-modal>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      mapId: 'mapId', // 地图id，多次调用该地图组件时，id必须动态生成
      map: null,
      address: '',
      params: {},
      longitude: '',
      latitude: '',
      name: ''
    }
  },
  watch: {
      // 经纬度
    point: function (val, old) {
      this.map.setCenter(val)
      this.marker.setPosition(val)
    }
  },
  mounted () {
  },
  created () {
  },
  methods: {
    handleCancel() {
        this.name = ''
        this.longitude = ''
        this.latitude = ''
        this.address = ''
        this.params = {}
    },
    save() {
        this.params.longitude = this.longitude
        this.params.latitude = this.latitude
        this.params.address = this.address
        this.$emit('save', this.params)
    },
    showMap(params) {
        this.visible = true
        this.createMap(params)
    },
    createMap (params) {
    this.params = params
    this.name = this.params.name
      const url = 'https://webapi.amap.com/maps?v=2.0&key=2b204e0f00c23d4a9c09a4e35ba88150&callback=onMapLoad&plugin=AMap.PlaceSearch,AMap.AutoComplete'
      const jsapi = document.createElement('script')
      jsapi.charset = 'utf-8'
      jsapi.src = url
      document.head.appendChild(jsapi)
      const _this = this
      this.mapId = `map${Math.random().toString().slice(4, 6)}`
      window.onMapLoad = function () {
        console.log('window.AMap', AMapUI)
        const map = new window.AMap.Map(_this.mapId, {
          resizeEnable: true, // resizeEnable: true, //是否监控地图容器尺寸变化
          zoom: 11, // zoom:11, //初始化地图层级
        })
        _this.map = map
        let adcode = Number(_this.params.areaCode.substring(0,6))
        window.AMapUI.load(['ui/geo/DistrictExplorer'], function(DistrictExplorer) {
            _this.districtExplorers = new DistrictExplorer({//创建一个实例
                map: _this.map,
                eventSupport: true, //打开事件支持
            });
            _this.districtExplorers.loadAreaNode(adcode, function(error, areaNode) {

                if (error) {
                    console.error(error);
                    return;
                }
                console.log(areaNode)
                //绘制载入的区划节点
                renderAreaNode(_this.districtExplorers, areaNode);
            });
            function renderAreaNode(districtExplorer, areaNode) {
                    //清除已有的绘制内容
                districtExplorer.clearFeaturePolygons();

                //just some colors
                var colors = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00"];

                //绘制子级区划
                districtExplorer.renderSubFeatures(areaNode, function(feature, i) {

                    var fillColor = colors[i % colors.length];
                    var strokeColor = colors[colors.length - 1 - i % colors.length];

                    return {
                        cursor: 'default',
                        bubble: true,
                        strokeColor: strokeColor, //线颜色
                        strokeOpacity: 1, //线透明度
                        strokeWeight: 1, //线宽
                        fillColor: fillColor, //填充色
                        fillOpacity: 0.35, //填充透明度
                    };
                });

                //绘制父级区划，仅用黑色描边
                districtExplorer.renderParentFeature(areaNode, {
                    cursor: 'default',
                    bubble: true,
                    strokeColor: 'black', //线颜色
                    fillColor: "#3366cc",  
                    strokeWeight: 3, //线宽
                });
                _this.map.setFitView(districtExplorer.getAllFeaturePolygons());
            }
        });
        var autoOptions = {
            input: "tipinput"
        };

        AMap.plugin(['AMap.PlaceSearch','AMap.AutoComplete'], function(){
            var auto = new AMap.AutoComplete(autoOptions);
            var placeSearch = new AMap.PlaceSearch({
                map: _this.map
            });  //构造地点查询类
            const that = _this
            auto.on("select", select);//注册监听，当选中某条记录时会触发
            function select(e) {
                placeSearch.setCity(e.poi.adcode);
                placeSearch.search(e.poi.name);  //关键字查询查询\
                let tmp = JSON.parse(JSON.stringify(e))
                that.longitude = tmp.poi.location[0]
                that.latitude = tmp.poi.location[1]
                that.address = tmp.poi.address
                _this.address = tmp.poi.district + tmp.poi.address
            }
        });
    }
}
    },
}
</script>

<style lang="scss">
#tipinput{
    width: 200px;
    margin-bottom: 10px;
}
</style>